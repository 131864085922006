import { Box, Card, styled, Typography } from '@mui/material';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useListBusinessUsersQuery } from 'src/pages/Settings/UserPermissions/query/useListBusinessUsersQuery';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';
import { BusinessUser } from 'src/domain/user/BusinessUser';
import { UserRole } from 'src/domain/user/UserRole';
import { UserManagementSection } from 'src/enterprise/configuration-replication/pages/UserManagementPage/Table/UserManagementSection';
import { UserManagementSearchBar } from 'src/enterprise/configuration-replication/pages/UserManagementPage/UserManagementSearchBar';
import { useSearchParams } from 'react-router';

const PageCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px 16px;
`;

const PageCardHeader = styled(Box)`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

const HeaderTitleWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
`;

const PageCardBody = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

type GroupedUsers = {
  [key in UserRole]?: BusinessUser[];
};

export const UserManagementPage = () => {
  const intl = useIntl();
  const businessId = useBusinessIdOrThrow();
  const { data, isLoading } = useListBusinessUsersQuery(businessId);

  const [users, setUsers] = useState<BusinessUser[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (data) {
      setUsers(data);
    }
  }, [data]);

  useEffect(() => {
    if (searchParams.get('search_query')) {
      setSearchQuery(searchParams.get('search_query') ?? '');
    }
  }, [searchParams]);

  const handleOnSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchQuery(value);
    setSearchParams({ search_query: value });
  };

  const handleOnClear = () => {
    setSearchQuery('');
    setSearchParams({ search_query: '' });
  };

  const filteredUsers = useMemo(() => {
    const lowercasedQuery = searchQuery.toLowerCase();

    if (searchQuery === '') {
      return users.map((user) => ({
        ...user,
        name: `${user.firstName ?? ''} ${user.lastName ?? ''}`,
      }));
    }

    return users
      .filter(
        (user) =>
          user.firstName?.toLowerCase().includes(lowercasedQuery) ||
          user.lastName?.toLowerCase().includes(lowercasedQuery) ||
          user.email.toLowerCase().includes(lowercasedQuery) ||
          user.phoneNumber?.toLowerCase().includes(lowercasedQuery),
      )
      .map((user) => ({
        ...user,
        name: `${user.firstName ?? ''} ${user.lastName ?? ''}`,
      }));
  }, [searchQuery, users]);

  const groupedUsers = useMemo(() => {
    return filteredUsers.reduce((groupedUser, user) => {
      const role = user.role;

      if (!groupedUser[role]) {
        groupedUser[role] = [];
      }

      groupedUser[role]?.push(user);
      return groupedUser;
    }, {} as GroupedUsers);
  }, [filteredUsers]);

  const admin = groupedUsers.ADMIN || [];
  const generalManagers = groupedUsers.GENERAL_MANAGER || [];
  const managers = groupedUsers.MANAGER || [];
  const staff = groupedUsers.STAFF || groupedUsers.WAITER || [];

  if (isLoading || !groupedUsers) return null;

  return (
    <PageCard>
      <PageCardHeader>
        <HeaderTitleWrapper>
          <Typography sx={{ fontSize: '20px' }}>{intl.formatMessage({ id: 'user_management.title' })}</Typography>
          <Typography
            sx={{
              fontSize: '14px',
              lineHeight: '16px',
              letterSpacing: '-0.42px',
            }}
          >
            {intl.formatMessage({ id: 'user_management.subTitle' })}
          </Typography>
        </HeaderTitleWrapper>
      </PageCardHeader>
      <UserManagementSearchBar onSearch={handleOnSearch} searchQuery={searchQuery} onClear={handleOnClear} />
      <PageCardBody>
        <UserManagementSection title={intl.formatMessage({ id: 'user_management.roles.admin' })} data={admin} />

        <UserManagementSection
          title={intl.formatMessage({ id: 'user_management.roles.general_manager' })}
          data={generalManagers}
        />
        <UserManagementSection title={intl.formatMessage({ id: 'user_management.roles.manager' })} data={managers} />
        <UserManagementSection title={intl.formatMessage({ id: 'user_management.roles.staff' })} data={staff} />
      </PageCardBody>
    </PageCard>
  );
};
