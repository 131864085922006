import axios from 'axios';
import { useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import moment from 'moment-timezone';
import { createReportingDigest } from '../model/createReportingDigest';
import { EndOfServiceClient } from '../infrastructure/EndOfServiceClient';
import {
  RestaurantShiftTimesOption,
  restaurantShiftTimesOptionTypes,
} from '../../restaurant-shifts/models/RestaurantShiftTimesOption';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { Payments } from '../model/Payments';
import { listRevenueCenters } from '../model/RevenueCenter';
import { useRestaurantShiftTimesOptions } from '../../restaurant-shifts/hooks/useRestaurantShiftTimesOptions';
import { EndOfServiceConfiguration } from '../model/EndOfServiceConfiguration';
import { Walkouts } from 'src/operations/end-of-service/model/Walkouts';
import {
  createReconciliationReportingDigest,
} from 'src/operations/end-of-service/model/createReconciliationReportingDigest';
import {
  getStartAndEndDateForChosenDateAndShiftOptions,
} from 'src/operations/end-of-service/hooks/getStartAndEndDateForChosenDateAndShiftOptions';
import { useSearchParams } from 'react-router';

export const useEndOfServiceSummary = (eosConfiguration: EndOfServiceConfiguration) => {
  const business = useCurrentBusinessOrThrow();

  const [searchParams] = useSearchParams();
  const initialReportingDate = new Date(searchParams.get('reportingDate') || moment().toDate().toISOString());
  const [reportingDate, setReportingDate] = useState(initialReportingDate);
  const [revenueCentersSelected, setRevenueCentersSelected] = useState<string[]>([]);
  const [walkouts, setWalkouts] = useState<Walkouts>({ walkouts: [] });
  const [payments, setPayments] = useState<Payments>({ payments: [] });
  const [revenueCentersOptions, setRevenueCentersOptions] = useState<string[]>([]);
  const { loaded, options } = useRestaurantShiftTimesOptions();

  useEffect(() => {
    setReportingDate(initialReportingDate);
  }, [searchParams]);

  const [restaurantShiftTimesOption, setRestaurantShiftTimesOption] = useState<RestaurantShiftTimesOption>(
    options[restaurantShiftTimesOptionTypes.ALL_SHIFTS],
  );

  const reconciliationClient = new EndOfServiceClient(axios);

  const fetchEndOfServiceData = async () => {
    // Sylvain: hack (need to invest more time) because options is not updated when the shift are really loaded so the value restaurantShiftTimesOption is wrong
    const { startAtLocalBusiness, endAtLocalBusiness } = getStartAndEndDateForChosenDateAndShiftOptions(
      options,
      restaurantShiftTimesOption,
      reportingDate,
      business,
    );

    const data = await reconciliationClient.summaryOnAPeriod(business.id, startAtLocalBusiness, endAtLocalBusiness);
    setPayments(data);

    const walkoutsData = await reconciliationClient.walkoutsOnAPeriod(
      business.id,
      startAtLocalBusiness,
      endAtLocalBusiness,
    );
    setWalkouts(walkoutsData);

    const revenueCenterOptions = listRevenueCenters(data.payments);

    setRevenueCentersOptions(revenueCenterOptions);
    setRevenueCentersSelected(revenueCenterOptions);
  };

  useEffect(() => {
    if (!loaded) {
      return;
    }
    fetchEndOfServiceData();
  }, [reportingDate, restaurantShiftTimesOption, loaded]);

  const reportingDigest = useMemo(() => {
    const currency =
      payments.payments && payments.payments.length > 0 ? payments.payments[0].salesAmount.currency : business.currency;
    return createReportingDigest(eosConfiguration, payments, currency, revenueCentersSelected);
  }, [payments, revenueCentersSelected]);

  const reconciliationDigest = useMemo(() => {
    return createReconciliationReportingDigest(walkouts, payments, business.currency);
  }, [walkouts, payments]);

  return {
    reportingDigest,
    reportingDate,
    setReportingDate,
    restaurantShiftTimesOption,
    setRestaurantShiftTimesOption,
    revenueCentersSelected,
    setRevenueCentersSelected,
    revenueCentersOptions,
    reconciliationDigest,
    payments,
  };
};
