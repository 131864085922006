// eslint-disable-next-line no-restricted-imports
import moment from 'moment';
import React, { useState } from 'react';
import { Button, DateTimePicker, Dropdown } from '@sundayapp/b2b-react-component-library';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { BillToDisplay } from './BillToDisplay';
import { BillsContainer, BillsWrapper, FiltersWrapper, SearchTextWrapper } from './index.style';
import { BILLS_PER_PAGE, BillsTable } from './BillsTable';
import { useBills } from './useBills';
import { NoPos } from 'src/pages/NoPos/NoPos';
import { useBusinessIsNoPos } from 'src/business/hooks/useBusinessIsNoPos';
import { BillStatus, billStatus } from '../domain/Bill';
import { hasTabBillsToDisplay } from './hasTabBillsToDisplay';
import Spinner from 'src/ordering/common/components/Spinner';
import { ampli } from 'src/ampli';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

function findByCheckNumber(bill: BillToDisplay, checkNumberFilter: string): boolean {
  if (!checkNumberFilter) {
    return true;
  }
  return !!(bill.order.checkNumber?.toLowerCase().split(', ') || []).find((checkNumber) =>
    checkNumber.startsWith(checkNumberFilter.toLowerCase()),
  );
}

function filterBills(
  bills: BillToDisplay[],
  selectedWaiter: string,
  tableFilter: string,
  checkNumberFilter: string,
  staffNameDefault: string,
  onlyOpened: boolean,
): BillToDisplay[] {
  return bills
    .filter(
      (bill) =>
        selectedWaiter === staffNameDefault ||
        bill.order.staffName?.toLowerCase()?.includes(selectedWaiter.toLowerCase()),
    )
    .filter((bill) => findByCheckNumber(bill, checkNumberFilter))
    .filter((bill) =>
      onlyOpened ? billStatus(bill) !== BillStatus.SETTLED && billStatus(bill) !== BillStatus.EMPTY : true,
    )
    .filter(
      (bill) =>
        !tableFilter ||
        bill.tableNumber.toLowerCase().startsWith(tableFilter.toLowerCase()) ||
        bill.openTab?.displayId?.toLowerCase().includes(tableFilter.toLowerCase()) ||
        bill.openTab?.userName?.toLowerCase().includes(tableFilter.toLowerCase()),
    );
}

const SearchLimit = ({ size }: { size: number }) => {
  return (
    <Typography
      style={{
        paddingLeft: '15px',
        opacity: '50%',
      }}
    >
      {size ? (
        size == 1000 ? (
          <FormattedMessage id="orders.search.limit.reached" values={{ searchLimit: size }} />
        ) : (
          <FormattedMessage id="orders.search.limit.not.reached" values={{ searchLimit: size }} />
        )
      ) : (
        <FormattedMessage id="orders.search.no.result" />
      )}
    </Typography>
  );
};

const SearchTooOld = ({ removeFlag }: { removeFlag: () => void }) => {
  const business = useCurrentBusinessOrThrow();

  return (
    <Box margin="auto" padding="10%">
      <Typography>
        <FormattedMessage
          id="orders.too_old_warning"
          defaultMessage={
            "Any bills that are over two weeks old won't be visible going forward. To view them, please click {link}."
          }
          values={{
            link: (
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  removeFlag();
                  ampli.arxDisplayBillTooOldRequest({ venueId: business.id });
                }}
                style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
              >
                <FormattedMessage id="orders.too_old_warning.disable" defaultMessage="here" />
              </a>
            ),
          }}
        />
      </Typography>
    </Box>
  );
};

const Loading = () => <Box padding='10%' margin='auto'><Spinner /></Box>;

export const Bills = () => {
  const { formatMessage } = useIntl();
  const ALL_WAITERS_DEFAULT = formatMessage({ id: 'tables.waiter.all' });

  const [[from, to], setSelectedRange] = useState<Date[]>([
    moment().startOf('day').toDate(),
    moment().endOf('day').toDate(),
  ]);
  const [datePickerIsOpened, setDatePickerIsOpened] = useState<boolean>(false);
  const [selectedWaiter, setSelectedWaiter] = useState<string>(ALL_WAITERS_DEFAULT);
  const [tableFilter, setTableFilter] = useState<string>('');
  const [checkNumberFilter, setCheckNumberFilter] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);
  const [onlyOpenedBills, setOnlyOpenedBills] = useState(false);
  const [billTooOldWarningActivated, setBillTooOldWarningActivated] = useState(true);

  const displayBillTooOldWarning = billTooOldWarningActivated && moment().diff(from, 'days') > 14;

  const { data, refetch, isLoading } = useBills(from, to);
  const effectiveBills = data.bills || [];
  const hasCheckNumbers = effectiveBills.find((bill) => !!bill.order.checkNumber) !== undefined;
  const bills = filterBills(
    effectiveBills,
    selectedWaiter,
    tableFilter,
    checkNumberFilter,
    ALL_WAITERS_DEFAULT,
    onlyOpenedBills,
  );
  const waiters = [ALL_WAITERS_DEFAULT].concat(data.waiters || []);

  const pageCount = Math.ceil(bills.length / BILLS_PER_PAGE);
  const nextPage = () => setCurrentPage(Math.min(currentPage + 1, pageCount));
  const previousPage = () => setCurrentPage(Math.max(currentPage - 1, 1));
  const resetCurrentPage = () => setCurrentPage(1);

  const closeDatePicker = () => setDatePickerIsOpened(false);
  const toggleDatePicker = () => setDatePickerIsOpened(!datePickerIsOpened);

  const venueIsNoPos = useBusinessIsNoPos();
  if (venueIsNoPos) return <NoPos />;

  const changeSelectedRange = (range: Date[]) => {
    setSelectedRange(range);
    closeDatePicker();
    resetCurrentPage();
  };

  const pickWaiter = (waiter: string) => {
    setSelectedWaiter(waiter);
    resetCurrentPage();
  };

  const filterOnTable = (table: string) => {
    setTableFilter(table);
    resetCurrentPage();
  };

  const filterOnCheckNumberChanged = (checkNumber: string) => {
    setCheckNumberFilter(checkNumber);
    resetCurrentPage();
  };

  const filterOnlyOpened = (value: boolean) => {
    setOnlyOpenedBills(value);
    resetCurrentPage();
  };
  const hasTab = hasTabBillsToDisplay(bills, currentPage, BILLS_PER_PAGE);

  return (
    <BillsWrapper>
      <BillsContainer>
        <FiltersWrapper>
          <Button size="medium" variant="secondary" onClick={toggleDatePicker}>
            <FormattedMessage id="datepicker.title" defaultMessage="Select Date & Time Range" />
          </Button>
          {datePickerIsOpened && (
            <DateTimePicker
              onApply={changeSelectedRange}
              onClose={closeDatePicker}
              value={[from, to]}
              applyButtonLabel={formatMessage({ id: 'datepicker.apply' })}
              cancelButtonLabel={formatMessage({ id: 'datepicker.cancel' })}
              startTimeInputLabel={formatMessage({ id: 'datepicker.start-time' })}
              endTimeInputLabel={formatMessage({ id: 'datepicker.end-time' })}
            />
          )}
          <Dropdown
            size="medium"
            value={selectedWaiter}
            placeholder={formatMessage({ id: 'tables.waiter.all' })}
            options={waiters}
            onChange={pickWaiter}
            emptyOptionsMessage={formatMessage({
              id: 'dropdown.empty-options',
              defaultMessage: 'no any other options available',
            })}
            autoComplete
          />
          <SearchTextWrapper
            onChange={(e: any) => {
              filterOnTable(e.target.value);
            }}
            value={tableFilter}
            inputIcon="search"
            placeholder={formatMessage({ id: hasTab ? 'orders.table.table_number_or_tab' : 'orders.table.number' })}
          />
          {hasCheckNumbers && (
            <SearchTextWrapper
              onChange={(e: any) => {
                filterOnCheckNumberChanged(e.target.value);
              }}
              value={checkNumberFilter}
              inputIcon="search"
              placeholder={formatMessage({ id: 'orders.table.check_number.search' })}
            />
          )}

          <FormControlLabel
            control={<Checkbox checked={onlyOpenedBills} onChange={() => filterOnlyOpened(!onlyOpenedBills)} />}
            label={<FormattedMessage id="orders.filter.opened_order" defaultMessage="View only opened orders" />}
          />
        </FiltersWrapper>
        <SearchLimit size={bills.length} />
        {isLoading ? (
          <Loading />
        ) : displayBillTooOldWarning ? (
          <SearchTooOld removeFlag={() => setBillTooOldWarningActivated(false)} />
        ) : (
          <BillsTable
            bills={bills}
            hasCheckNumbers={hasCheckNumbers}
            currentPage={currentPage}
            pageCount={pageCount}
            previousPage={previousPage}
            nextPage={nextPage}
            refreshBill={data.refreshBill}
            refetch={refetch}
          />
        )}
      </BillsContainer>
    </BillsWrapper>
  );
};
