import {
  CheckoutOnboardingInformationDraft,
  Roles as RolesType,
} from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { Checkbox, FormControlLabel, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const RoleLabel = ({ role, tooltip }: { role: string, tooltip: string }) =>
  <Stack direction={'row'}
         spacing={1}
         alignItems={'center'}>
    <Typography>
      {role}
    </Typography>
    <Tooltip
      title={tooltip}
      arrow placement={'right-start'}>
      <InfoOutlinedIcon />
    </Tooltip>
  </Stack>;
export const Role = ({ representativeIndex, isReadOnly = false, role, label }: {
  representativeIndex: number,
  role: keyof RolesType,
  isReadOnly?: boolean,
  label: string
}) => {
  const {
    watch,
    control,
  } = useFormContext<CheckoutOnboardingInformationDraft>();
  const intl = useIntl();

  return <Controller
    key={`representatives.${representativeIndex}.roles.${role}`}
    name={`representatives.${representativeIndex}.roles.${role}`}
    control={control}
    render={({ field }) => (
      <FormControlLabel
        control={<Checkbox sx={{ alignSelf: 'flex-start' }} disabled={isReadOnly}
                           checked={!!watch(`representatives.${representativeIndex}.roles.${role}`)}
                           size={'large'} {...field} onChange={e => field.onChange(e)} />}
        label={<RoleLabel
          role={label}
          tooltip={intl.formatMessage({ id: `settings.payments.checkout.onboarding.representatives.roles.${role}.tooltip` })} />
        }
        labelPlacement="end"
      />
    )}
  />;
};
