import { UserManagementTable } from 'src/enterprise/configuration-replication/pages/UserManagementPage/Table/UserManagementTable';
import { Box, styled, Typography } from '@mui/material';
import { ChevronDownIcon } from 'src/enterprise/configuration-replication/components/icons/ChevronDownIcon';
import { useEffect, useState } from 'react';
import { ChevronLeftIcon } from 'src/enterprise/configuration-replication/components/icons/ChevronLeftIcon';
import { BusinessUser } from 'src/domain/user/BusinessUser';
import { useSearchParams } from 'react-router';

interface UserManagementSectionProps {
  title: string;
  data: BusinessUser[];
}

const UserManagementWrapper = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 8px;
  cursor: pointer;
`;

const UserManagementDisplayIcon = styled(Box)`
  display: flex;
`;

export const UserManagementSection = ({ title, data }: UserManagementSectionProps) => {
  const [showSection, setShowSectionTable] = useState(false);
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get('search_query') ?? '';

  useEffect(() => {
    if (searchQuery !== '') {
      setShowSectionTable(true);
    }
  }, [searchQuery, showSection]);

  const handleToggleSection = () => {
    setShowSectionTable(!showSection);
  };

  if (!data.length) return;

  return (
    <>
      <UserManagementWrapper onClick={handleToggleSection}>
        <Box>
          <Typography>{title}</Typography>
        </Box>
        <UserManagementDisplayIcon>{showSection ? <ChevronDownIcon /> : <ChevronLeftIcon />}</UserManagementDisplayIcon>
      </UserManagementWrapper>
      {data && showSection && <UserManagementTable data={data} />}
    </>
  );
};
