import { z } from 'zod';
import { Instant } from 'src/sundayplus/Instant';
import { virtualTabIdSchema } from 'src/bills/domain/tab/virtual_tab/VirtualTab';

export const virtualTabReadDtoSchema = z.object({
  tabId: virtualTabIdSchema,
  tableId: z.string(),
  billId: z.string().optional(),
  closedAt: z.string().optional().transform(s => !!s ? Instant.fromString(s) : undefined),
});

export type VirtualTabReadDto = z.infer<typeof virtualTabReadDtoSchema>;
