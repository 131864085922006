import axios from 'axios';
import {
  EMPTY_WHITE_LABEL_CUSTOMIZATION,
  WhiteLabelCustomization,
} from 'src/domain/customization/WhiteLabelCustomization';
import { WhiteLabelCustomizationRepository } from 'src/domain/customization/WhiteLabelCustomizationRepository';
import { WhiteLabelCustomizationDto } from 'src/infrastructure/venue/dto/WhiteLabelCustomizationDto';

export class WhiteLabelCustomizationRepositoryHttp implements WhiteLabelCustomizationRepository {
  constructor(private baseUrl: string) {
  }

  async getCustomization(enrollmentId: string): Promise<WhiteLabelCustomization> {
    try {
      const { data } = await axios.get<WhiteLabelCustomizationDto>(
        `${this.baseUrl}/enrollments/${enrollmentId}/customization/advanced`,
      );
      const burgerBanner = data.burgerBanners?.pop();
      return {
        activated: data.activated,
        loadingImgUrl: data.loadingImgUrl,
        color: {
          loadingPage: data.color.brand,
          marketingBanners: data.color.accent,
          appBackground: data.color.background,
        },
        burgerBanner: {
          title: burgerBanner?.title,
          description: burgerBanner?.description,
          linkLabel: burgerBanner?.linkLabel,
          linkUrl: burgerBanner?.linkUrl,
          imageUrl: burgerBanner?.imageUrl,
        },
        postPaymentBanner: {
          title: data.postPaymentBanner?.title,
          description: data.postPaymentBanner?.description,
          linkLabel: data.postPaymentBanner?.linkLabel,
          linkUrl: data.postPaymentBanner?.linkUrl,
          imageUrl: data.postPaymentBanner?.imageUrl,
        },
      };
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return EMPTY_WHITE_LABEL_CUSTOMIZATION;
      }
      throw error;
    }
  }

  async saveCustomization(enrollmentId: string, customization: WhiteLabelCustomization): Promise<boolean> {
    const data: WhiteLabelCustomizationDto = {
      activated: customization.activated,
      loadingImgUrl: customization.loadingImgUrl,
      color: {
        brand: customization.color.loadingPage,
        accent: customization.color.marketingBanners,
        background: customization.color.appBackground,
      },
      burgerBanners: customization.burgerBanner ? [customization.burgerBanner] : [],
      postPaymentBanner: {
        title: customization.postPaymentBanner?.title,
        description: customization.postPaymentBanner?.description,
        linkLabel: customization.postPaymentBanner?.linkLabel,
        linkUrl: customization.postPaymentBanner?.linkUrl,
        imageUrl: customization.postPaymentBanner?.imageUrl,
      },
    };
    const response = await axios.put(
      `${this.baseUrl}/enrollments/${enrollmentId}/customization/advanced`,
      data,
    );
    return response.status === 200;
  }
}
