import { useState } from 'react';
import {
  displayMenuMigration,
  MenuReplicationState,
} from 'src/enterprise/configuration-replication/domain/menu/MenuReplicationState';
import { Card, Stack } from '@mui/material';
import { SelectMenuMigrationStep } from 'src/enterprise/configuration-replication/components/menu/SelectMenuMigrationStep';
import { ReviewMenuMigrationStep } from 'src/enterprise/configuration-replication/components/menu/ReviewMenuMigrationStep';

export const MenuReplicationPage = () => {
  const [menuState, setMenuState] = useState<MenuReplicationState>(displayMenuMigration());

  const setNextMenuState = (nextState: MenuReplicationState) => setMenuState(nextState);

  return (
    <Stack gap="1rem">
      <Card>
        {menuState.step === 'select menu migration' && (
          <SelectMenuMigrationStep currentState={menuState} onNext={setNextMenuState} />
        )}
        {menuState.step === 'menu review' && (
          <ReviewMenuMigrationStep currentState={menuState} onEdit={setNextMenuState} />
        )}
      </Card>
    </Stack>
  );
};
