import { Stack } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { LegalName } from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/ControllingCompanies/LegalName';
import { RegistrationCountry } from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/ControllingCompanies/RegistrationCountry';
import { isControllingCompanyComplete } from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/ControllingCompanies/isControllingCompanyComplete';
import { ControllingCompanyOwnershipPercentage } from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/PersonalInfo/ControllingCompanyOwnershipPercentage';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { Representative } from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/Representative';
import { IsCompanyTradingNameSameAsLegalName } from './IsCompanyTradingNameSameAsLegalName';
import { TradingName } from './TradingName';

export const EditControllingCompany = ({ index, onDelete }: { onDelete: () => void; index: number }) => {
  const {
    formState: { errors },
    watch,
  } = useFormContext<CheckoutOnboardingInformationDraft>();
  const error = errors?.controllingCompanies?.[index];
  const controllingCompany = watch(`controllingCompanies.${index}`);
  const isComplete = isControllingCompanyComplete({ controllingCompany, containsErrors: !!error });
  const intl = useIntl();
  const title =
    controllingCompany.legalName ??
    intl.formatMessage({ id: 'settings.payments.checkout.onboarding.controllingCompanies.details' });
  const isTradingNameTheSameAsLegalName = watch(`controllingCompanies.${index}.isTradingNameTheSameAsLegalName`);
  return (
    <Representative title={title} isComplete={isComplete} onDelete={onDelete}>
      <Stack spacing={2}>
        <RegistrationCountry representativeIndex={index} />
        <ControllingCompanyOwnershipPercentage representativeIndex={index} />
        <LegalName legalRepresentativeIndex={index} />
        <IsCompanyTradingNameSameAsLegalName representativeIndex={index} />
        {!isTradingNameTheSameAsLegalName && <TradingName representativeIndex={index} />}
      </Stack>
    </Representative>
  );
};
