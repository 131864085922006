import { useFormContext } from 'react-hook-form';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect } from 'react';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { isUs } from 'src/payments/components/Checkout/CheckoutOnboarding/IsUs';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const useRemoveNationalIdNumberOnCountryOfResidenceChange = ({ representativeIndex }: {
  representativeIndex: number
}) => {
  const { watch, unregister } = useFormContext<CheckoutOnboardingInformationDraft>();
  const country = watch(`representatives.${representativeIndex}.address.country`);
  useEffect(() => {
    if (country !== 'US') {
      unregister(`representatives.${representativeIndex}.nationalIdNumber`);
    }
  }, [country, representativeIndex]);
};


const NationalIdNumber = ({ legalRepresentativeIndex }: {
  legalRepresentativeIndex: number
}) => {
  const intl = useIntl();
  const { formState: { errors }, register, watch } = useFormContext<CheckoutOnboardingInformationDraft>();
  const nationalIdNumber = watch(`representatives.${legalRepresentativeIndex}.nationalIdNumber`);
  const last4NationalIdNumber = nationalIdNumber?.slice(-4);
  const theme = useTheme();
  const [previewIdNumberLast4, setPreviewIdNumberLast4] = React.useState(false);
  const error = errors?.representatives?.[legalRepresentativeIndex]?.nationalIdNumber;
  const previewNationalIdNumber = nationalIdNumber ? '*'.repeat(5) + last4NationalIdNumber : '';
  return <Stack spacing={1}><TextField
    required
    error={!!error}
    type={'password'}
    InputLabelProps={{ shrink: true }}
    {...register(`representatives.${legalRepresentativeIndex}.nationalIdNumber`, {
      pattern: {
        value: /^\d{9}$/,
        message: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.representatives.nationalIdNumber.error.pattern' }),
      },
    })}
    autoComplete={'new-password'}
    slotProps={{
      input: {
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label={
                previewIdNumberLast4 ? 'hide national id number' : 'display national id number'
              }
              onClick={() => setPreviewIdNumberLast4(!previewIdNumberLast4)}
            >
              {previewIdNumberLast4 ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      },
    }}
    label={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.representatives.nationalIdNumber' })}
    helperText={error?.message || intl.formatMessage({ id: 'settings.payments.checkout.onboarding.representatives.nationalIdNumber.helperText' })}
  />
    <Dialog open={previewIdNumberLast4} onClose={() => setPreviewIdNumberLast4(false)}>
      <DialogTitle>
        <Typography variant={'body1'}>
          <FormattedMessage id="settings.payments.checkout.onboarding.representatives.nationalIdNumber.last4" />
        </Typography>
      </DialogTitle>
      <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant={'caption'} color={theme.palette.text.secondary}>
          {previewNationalIdNumber}
        </Typography>
      </DialogContent>
    </Dialog>
  </Stack>;
};

export const needsNationalIdNumber = (businessCountryCode: string, residenceCountryCode: string) => isUs(businessCountryCode) && isUs(residenceCountryCode);

export const NationalIdNumberIfUS = ({ businessCountryCode, representativeIndex }: {
  businessCountryCode: string,
  representativeIndex: number
}) => {
  const { watch } = useFormContext<CheckoutOnboardingInformationDraft>();
  useRemoveNationalIdNumberOnCountryOfResidenceChange({ representativeIndex });
  const residenceCountryCode = watch(`representatives.${representativeIndex}.address.country`);

  return <>
    {needsNationalIdNumber(businessCountryCode, residenceCountryCode ?? '') &&
      <NationalIdNumber legalRepresentativeIndex={representativeIndex} />}
  </>;
};
