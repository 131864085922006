import { z } from 'zod';

export const virtualTabIdSchema = z.string().brand<'virtual_tab_id'>();
export type VirtualTabId = z.infer<typeof virtualTabIdSchema>;

export interface VirtualTab {
  id: VirtualTabId,
  billId: string,
  canBeCaptured: boolean,
}
