import React from 'react';
import { OrderingReportingPanel } from './OrderingReporting';
import { DateRange } from '../domain/DateRange';
import { Business } from 'src/business/domain/Business';

type NonUSReportingViewProps = {
  selectedBusiness: Business | undefined;
  reportingDateRange: DateRange;
};

export const NonUSReportingView = ({ selectedBusiness, reportingDateRange }: NonUSReportingViewProps) => {
  if (!selectedBusiness || !selectedBusiness.oapEnrollment) {
    return <>This page is only available for O&P business</>;
  }
  return (
    <OrderingReportingPanel
      businessId={selectedBusiness.id}
      enrollmentId={selectedBusiness.oapEnrollment.id}
      countryCode={selectedBusiness.address.countryCode}
      reportingDateRange={reportingDateRange}
    />
  );
};
