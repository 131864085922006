import { useContext, useState } from 'react';
import { ConfigurationContext } from 'src/app/contexts/configuration/ConfigurationProvider';
import { initEffects } from '../../effects';
import { BusinessId } from 'src/business/domain/Business';
import { Money } from '@sundayapp/web-money';

export type CaptureStatus = 'not_started' | 'capturing' | 'success' | 'error';

export const useVirtualTabCapture = (businessId: BusinessId, tabId: string) => {
  const [status, setStatus] = useState<CaptureStatus>('not_started');
  const configuration = useContext(ConfigurationContext);
  const { till } = initEffects(configuration);

  return {
    status,
    capture: async (amount: Money) => {
      setStatus('capturing');
      try {
        await till.captureVirtualTabLeftToPay(tabId, amount);
      } catch (err) {
        setStatus('error');
        return;
      }
      setStatus('success');
    },
  };
};
