import React from 'react';
import { Money, moneyToUnit } from '@sundayapp/web-money';
import CsvDownloader from 'react-csv-downloader';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import {
  CardBrand,
  computePaymentStatus,
  PaymentForOrdering,
  PaymentForPat,
  PaymentMethodRef,
  PaymentMethodType,
  PaymentProvider,
  SpecificPayment,
} from '../domain/Payment';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { Button } from '@mui/material';
import { Download } from '@mui/icons-material';

const displayCsvExportAmount = ([initialAmount, remainingAmount]: [Money, Money]): string => {
  if (remainingAmount.amount === initialAmount.amount || remainingAmount.amount === 0) {
    return moneyToUnit(initialAmount).toFixed(2);
  }

  return `${moneyToUnit(remainingAmount).toFixed(2)} -- ${moneyToUnit(initialAmount).toFixed(2)}`;
};

export const displayCsvTotalAmount = (specificPayment: SpecificPayment) =>
  displayCsvExportAmount(specificPayment.payment.computeTotal());

interface Props {
  payments: SpecificPayment[];
  status?: string;
}

const getPaymentProviderDisplays = (
  paymentProvider: PaymentProvider,
  paymentMethodType: PaymentMethodType,
): string | undefined => {
  switch (paymentProvider) {
    case 'STRIPE':
      return 'sunday';
    case 'EDENRED':
      return 'edenred';
    case 'TOAST':
      return 'sunday';
    case 'PAY_CRITICAL':
      return 'ticket restaurant';
    case 'PAY_GREEN':
      return paymentMethodType;
    case 'THUNES':
      return 'pluxee';
    case 'CHECKOUT':
      return 'sunday';
    case 'NEPTING':
      return 'sunday';
    case 'DUMMY':
      return 'sunday';
    case 'DNA':
      return 'sunday';
    case 'PAYROC':
      return 'sunday';
    default:
      throw new Error(paymentProvider);
  }
};

export function getPaymentMethodDetails(
  paymentProvider: PaymentProvider,
  paymentMethodType: PaymentMethodType,
  paymentMethodRef: PaymentMethodRef | undefined,
  cardBrand: CardBrand | undefined,
): { provider: string; brand?: string; last4?: string } {
  const paymentProviderInfo = getPaymentProviderDisplays(paymentProvider, paymentMethodType);

  if (paymentProviderInfo === undefined) {
    return { provider: 'UNKNOWN' };
  }
  const strings = ['bancontact', 'ideal'];
  if (strings.find((pm) => pm === paymentMethodType)) {
    return {
      provider: 'sunday',
      brand: paymentMethodType,
      last4: paymentMethodRef && paymentMethodRef.length > 0 ? paymentMethodRef : undefined,
    };
  }
  return { provider: paymentProviderInfo, brand: cardBrand, last4: paymentMethodRef };
}

export function displayPaymentMethod(
  paymentProvider: PaymentProvider,
  paymentMethodType: PaymentMethodType,
  paymentMethodRef: PaymentMethodRef | undefined,
  cardBrand: CardBrand | undefined,
): string {
  const details = getPaymentMethodDetails(paymentProvider, paymentMethodType, paymentMethodRef, cardBrand);
  const isBrandShorten = details.brand && details.brand.slice(4, details.brand.length).length > 0;
  const brandDisplay = `${details.brand?.slice(0, 4) ?? ''}${isBrandShorten ? '.' : ''}`;
  const spaceDisplay = brandDisplay ? ' ' : '';
  const spaceLast4 = details.last4 ? ' ' : '';
  return `${details.provider}${spaceDisplay}${brandDisplay ?? ''}${spaceLast4}${
    details.last4 ? `*${details.last4}` : ''
  }`.trim();
}

const getFirstColumn = (payment: SpecificPayment): { [key: string]: string } =>
  payment.type === 'pay-at-table'
    ? { id: (payment as PaymentForPat).tableNumber ?? '' }
    : { id: (payment as PaymentForOrdering).orderDisplayId ?? '' };

const CsvExportButton = ({ payments, status }: Props) => {
  const intl = useIntl();
  const business = useCurrentBusinessOrThrow();

  const exportPaymentsToCsv = () =>
    payments.map((specificPayment): { [key: string]: string } => ({
      ...getFirstColumn(specificPayment),
      method: displayPaymentMethod(
        specificPayment.payment.paymentProvider,
        specificPayment.payment.paymentMethodType,
        specificPayment.payment.paymentMethodRef,
        specificPayment.payment.cardBrand,
      ),
      time: `${intl.formatDate(new Date(specificPayment.payment.timestamp).toString())} ${intl.formatTime(
        new Date(specificPayment.payment.timestamp).toString(),
      )}`,
      tips: displayCsvExportAmount(specificPayment.payment.computeTips()),
      subtotal: displayCsvExportAmount(specificPayment.payment.computeSubtotal()),
      total: displayCsvExportAmount(specificPayment.payment.computeTotal()),
      status: intl.formatMessage({ id: `payments.status.${computePaymentStatus(specificPayment.payment, status)}` }),
      waiter: specificPayment.payment.waiterName ?? '',
    }));

  const columnsDefinition = [
    {
      id: 'id',
      displayName: 'id',
    },
    {
      id: 'method',
      displayName: intl.formatMessage({ id: 'payments.method' }),
    },
    {
      id: 'time',
      displayName: intl.formatMessage({ id: 'payments.time' }),
    },
    {
      id: 'tips',
      displayName: intl.formatMessage({ id: 'payments.tips' }),
    },
    {
      id: 'subtotal',
      displayName: intl.formatMessage({ id: 'payments.sub_total' }),
    },
    {
      id: 'total',
      displayName: intl.formatMessage({ id: 'payments.total' }),
    },
    {
      id: 'status',
      displayName: intl.formatMessage({ id: 'payments.status' }),
    },
    {
      id: 'waiter',
      displayName: intl.formatMessage({ id: 'payments.waiter_name' }),
    },
  ];

  const buildFileName = (): string => `sunday - ${business.id.toString()}.csv`;

  return (
    <CsvDownloader
      filename={buildFileName()}
      wrapColumnChar='"'
      columns={columnsDefinition}
      datas={exportPaymentsToCsv()}
    >
      <Button size="small" variant="contained" startIcon={<Download />}>
        <FormattedMessage id="payments.export_btn" />
      </Button>
    </CsvDownloader>
  );
};

export default CsvExportButton;
