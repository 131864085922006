import { ShiftHour } from './ShiftHour';

export class Shift {
  constructor(
    public businessId: string,
    public startShiftHour: ShiftHour,
    public endShiftHour: ShiftHour,
    public shiftType: 'LUNCH' | 'DINNER',
    public id?: string,
  ) {}
}
