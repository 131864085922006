import { CurrencyCode, money } from '@sundayapp/web-money';
import { emptyPdqReporting, Reporting } from './ReportingDigest';
import { includeContratMonetique, isCB, isOnPdq, isTrd, Payment, Payments, serviceChargesOrZero } from './Payments';
import { safeSumMoney } from 'src/utils/MoneyUtils';
import { paymentHasRevenueCenter, toSchemeReporting } from './createReportingDigest';
import { contratMonetique, ContratMonetique, sortedContratMonetiques } from './ContratMonetique';
import { EndOfServiceConfiguration } from './EndOfServiceConfiguration';
import { emptyWaitersReporting, isCnp, isCp, KindOfPayment, mapProductToKindOfPayment, products } from './products';

const toTpeReportingByCardScheme = (payments: Payment[], currency: CurrencyCode, scheme: string) =>
  payments.reduce(
    (acc, p) => {
      return {
        name: acc.name,
        numberOfPayment: payments.length,
        product: products.PDQ,
        revenue: safeSumMoney([acc.revenue, p.salesAmount], currency),
        tips: safeSumMoney([acc.tips, p.tipsAmount], currency),
        sundayTips: safeSumMoney([acc.sundayTips, p.sundayTipsAmount], currency),
        serviceCharges: safeSumMoney([acc.serviceCharges, serviceChargesOrZero(p, currency)], currency),
        total: safeSumMoney(
          [acc.total, safeSumMoney([p.salesAmount, serviceChargesOrZero(p, currency), p.tipsAmount], currency)],
          currency,
        ),
        details: [],
      };
    },
    emptyPdqReporting(scheme, currency),
  );

const toTpeReportingByCardSchemes = (payments: Payment[], currency: CurrencyCode) => {
  const cardSchemes = new Set(payments.map((p) => p.scheme));
  return Array.from(cardSchemes).map((scheme) =>
    toTpeReportingByCardScheme(
      payments.filter((p) => p.scheme === scheme),
      currency,
      scheme,
    ),
  );
};

const toTpeReportingByContratMonetique = (payments: Payment[], currency: CurrencyCode, contrat: ContratMonetique) => {
  const reporting = payments.reduce(
    (acc, p) => {
      return {
        name: acc.name,
        numberOfPayment: payments.length,
        product: products.PDQ,
        revenue: safeSumMoney([acc.revenue, p.salesAmount], currency),
        tips: safeSumMoney([acc.tips, p.tipsAmount], currency),
        sundayTips: safeSumMoney([acc.sundayTips, p.sundayTipsAmount], currency),
        serviceCharges: safeSumMoney([acc.serviceCharges, serviceChargesOrZero(p, currency)], currency),
        total: safeSumMoney(
          [acc.total, safeSumMoney([p.salesAmount, serviceChargesOrZero(p, currency), p.tipsAmount], currency)],
          currency,
        ),
        details: [],
      };
    },
    emptyPdqReporting(contrat, currency),
  );

  reporting.details = toTpeReportingByCardSchemes(payments, currency);

  return reporting;
};

const toTpeReportingByContratMonetiques = (payments: Payment[], currency: CurrencyCode): Reporting[] => {
  const contratMonetiques = includeContratMonetique(payments, contratMonetique.ANCV)
    ? sortedContratMonetiques()
    : sortedContratMonetiques().filter((c) => c !== contratMonetique.ANCV);

  return contratMonetiques.map((cc) =>
    toTpeReportingByContratMonetique(
      payments.filter((p) => p.contratMonetique === cc),
      currency,
      cc,
    ),
  );
};

export const toTpePaymentMethodReporting = (
  name: string,
  payments: Payments,
  currency: CurrencyCode,
  revenueCentersSelected: string[] = [],
): Reporting => {
  const filteredPayments = payments.payments.filter(
    (payment) => isOnPdq(payment) && paymentHasRevenueCenter(revenueCentersSelected, payment),
  );

  const reportingByContratMonetiques = toTpeReportingByContratMonetiques(filteredPayments, currency);

  const revenue = reportingByContratMonetiques.reduce(
    (acc, reporting) => safeSumMoney([acc, reporting.revenue], currency),
    money(0, currency),
  );
  const tips = reportingByContratMonetiques.reduce(
    (acc, reporting) => safeSumMoney([acc, reporting.tips], currency),
    money(0, currency),
  );
  const sundayTips = reportingByContratMonetiques.reduce(
    (acc, reporting) => safeSumMoney([acc, reporting.sundayTips], currency),
    money(0, currency),
  );
  const serviceCharges = reportingByContratMonetiques.reduce(
    (acc, reporting) => safeSumMoney([acc, reporting.serviceCharges], currency),
    money(0, currency),
  );
  return {
    name,
    numberOfPayment: filteredPayments.length,
    product: products.PDQ,
    revenue,
    tips,
    sundayTips,
    serviceCharges,
    total: safeSumMoney([revenue, serviceCharges, tips], currency),
    details: reportingByContratMonetiques,
  };
};

export const toWaiterProductsReportingByPaymentContract = (
  eosConfiguration: EndOfServiceConfiguration,
  waiterPayments: Payment[],
  currency: CurrencyCode,
): Reporting[] => {
  const result = waiterPayments.reduce((acc, payment) => {
    const kindOfPayment: KindOfPayment = mapProductToKindOfPayment(payment.origin);
    return {
      ...acc,
      [kindOfPayment]: {
        name: acc[kindOfPayment].name,
        numberOfPayment: waiterPayments.length,
        revenue: safeSumMoney([acc[kindOfPayment].revenue, payment.salesAmount], currency),
        tips: safeSumMoney([acc[kindOfPayment].tips, payment.tipsAmount], currency),
        serviceCharges: safeSumMoney(
          [acc[kindOfPayment].serviceCharges, serviceChargesOrZero(payment, currency)],
          currency,
        ),
        total: safeSumMoney(
          [
            acc[kindOfPayment].total,
            safeSumMoney([payment.salesAmount, serviceChargesOrZero(payment, currency), payment.tipsAmount], currency),
          ],
          currency,
        ),
        details: [],
      },
    };
  }, emptyWaitersReporting(currency));

  const waiterProductsReporting = Object.values(result);

  const cnpWaiterPayments = waiterPayments.filter((p) => isCnp(p.origin));
  waiterProductsReporting[0].details = [
    toSchemeReporting(products.PAT, 'sales_summary.cb_total', cnpWaiterPayments, currency, isCB),
  ];
  waiterProductsReporting[0].numberOfPayment = cnpWaiterPayments.length;
  if (eosConfiguration.displayTrd)
    waiterProductsReporting[0].details.push(
      toSchemeReporting(products.PAT, 'sales_summary.trd_total', cnpWaiterPayments, currency, isTrd),
    );

  const cpWaiterPayments = waiterPayments.filter((p) => isCp(p.origin));
  waiterProductsReporting[1].details = toTpeReportingByContratMonetiques(cpWaiterPayments, currency);
  waiterProductsReporting[1].numberOfPayment = cpWaiterPayments.length;

  return waiterProductsReporting;
};
