import axios from 'axios';
import { useEffect, useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import moment from 'moment';
import { AccountingRepository } from '../infrastructure/AccountingRepository';
import { defaultRange, GetAccountingShiftUseCase } from '../usecase/GetAccountingShiftUseCase';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { BusinessId } from 'src/business/domain/Business';

export const useReporting = () => {
  const business = useCurrentBusinessOrThrow();
  const [reportingDateRange, setReportingDateRange] = useState(defaultRange.today);
  const [selectedRange, setSelectedRange] = useState<'today' | 'lunch' | 'dinner' | 'yesterday' | 'custom_range'>('today');
  const [accountingRanges, setAccountingRanges] = useState(defaultRange);

  const getAccountingShiftUseCase = new GetAccountingShiftUseCase(new AccountingRepository(axios));

  const fetchAccountingShift = async (businessId: BusinessId | undefined) => {
    if (businessId) {
      const rangesFromShift = await getAccountingShiftUseCase.getAccountingDateRange(businessId);
      setAccountingRanges(rangesFromShift);
    }
  };

  useEffect(() => {
    const dateNow = new Date(Date.now());

    if (selectedRange === 'today') {
      if (moment(dateNow).isAfter(accountingRanges.dinner.endDate)) {
        setReportingDateRange(accountingRanges.today.tomorrow());
      }
      if (moment(dateNow).isBefore(accountingRanges.dinner.endDate)) {
        setReportingDateRange(accountingRanges.today);
      }
    }

    if (selectedRange === 'lunch') {
      if (moment(dateNow).isAfter(accountingRanges.dinner.endDate)) {
        setReportingDateRange(accountingRanges.lunch.tomorrow());
      }
      if (moment(dateNow).isBefore(accountingRanges.dinner.endDate)) {
        setReportingDateRange(accountingRanges.lunch);
      }
    }

    if (selectedRange === 'dinner') {
      if (moment(dateNow).isAfter(accountingRanges.dinner.endDate)) {
        setReportingDateRange(accountingRanges.dinner.tomorrow());
      }
      if (moment(dateNow).isBefore(accountingRanges.dinner.endDate)) {
        setReportingDateRange(accountingRanges.dinner);
      }
    }

    if (selectedRange === 'yesterday') {
      setReportingDateRange(accountingRanges.today.yesterday());
    }
  }, [selectedRange, accountingRanges]);

  useEffect(() => {
    fetchAccountingShift(business.id);
  }, [business.id]);

  return {
    reportingDateRange,
    setSelectedRange,
    accountingRanges,
    setReportingDateRange,
  };
};
