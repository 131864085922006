import CsvDownloader from 'react-csv-downloader';
import { Button } from '@mui/material';
import { Download } from '@mui/icons-material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { ReplyTemplate } from '../domain/ReplyTemplate';
import { ampli } from 'src/ampli';

type Props = {
  replyTemplates: ReplyTemplate[];
};

export const ReplyTemplateCsvExporterButton = ({ replyTemplates }: Props) => {
  const columnSeparator = '"';

  const buildFileName = () => `reply-templates-${(new Date(Date.now()).toISOString())}`;

  const formatReplyTemplates = () => replyTemplates.map((replyTemplate) => ({
    name: replyTemplate.name,
    content: replyTemplate.content.replaceAll(columnSeparator, '\''),
    applicableRatings: replyTemplate.applicableRatings.join('-'),
  }));

  const columnsDefinition = [
    {
      id: 'name',
      displayName: 'name',
    },
    {
      id: 'content',
      displayName: 'content',
    },
    {
      id: 'applicableRatings',
      displayName: 'applicableRatings',
    },
  ];

  return (
    <CsvDownloader
      filename={buildFileName()}
      wrapColumnChar={columnSeparator}
      columns={columnsDefinition}
      datas={formatReplyTemplates()}
    >
      <Button
        color="primary"
        size="medium"
        variant="outlined"
        startIcon={<Download />}
        onClick={() => {
          ampli.exportReplyTemplatesClicked();
        }}
      >
        <FormattedMessage id="settings.reviews_and_loyalty.reply_template.list.export" />
      </Button>
    </CsvDownloader>
  );
};
