import { BusinessId } from 'src/business/domain/Business';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { RestaurantShiftTimes } from 'src/domain/venue/RestaurantShiftTimes';
import { VenueReferentialRepositoryHttp } from 'src/infrastructure/venue/VenueReferentialRepositoryHttp';
import { Shift } from '../domain/Shift';
import { ShiftHour } from '../domain/ShiftHour';

function getShiftHour(timeString: string): ShiftHour {
  return ShiftHour.fromHourAndMinute(timeString);
}

function toShift(businessId: BusinessId, response: RestaurantShiftTimes): Shift[] {
  return [
    new Shift(
      businessId,
      getShiftHour(response.lunch.from),
      getShiftHour(response.lunch.to),
      'LUNCH',
      'lunch',
    ),
    new Shift(
      businessId,
      getShiftHour(response.dinner.from),
      getShiftHour(response.dinner.to),
      'DINNER',
      'dinner',
    ),
  ];
}

export async function getShiftsByVenue(businessId: BusinessId) {
  const { venueReferentialUrl } = ConfigurationLoader.load();
  const client = new VenueReferentialRepositoryHttp(venueReferentialUrl);
  const shiftResponse = await client.getRestaurantShiftTimes(businessId);
  return toShift(businessId, shiftResponse);
}
