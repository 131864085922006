import { useMemo, useState } from 'react';
import { Table, TableBody } from '@mui/material';
import {
  TableOrder,
  UserManagementData,
  UserManagementTableHead,
} from 'src/enterprise/configuration-replication/pages/UserManagementPage/Table/UserManagementTableHead';
import { BusinessUser } from 'src/domain/user/BusinessUser';
import { UserManagementTableBody } from 'src/enterprise/configuration-replication/pages/UserManagementPage/Table/UserManagementTableBody';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  const aName = typeof a[orderBy] === 'string' ? a[orderBy] : '';
  const bName = typeof b[orderBy] === 'string' ? b[orderBy] : '';

  if (bName < aName) {
    return -1;
  }
  if (bName > aName) {
    return 1;
  }

  return 0;
}

function getComparator<Key extends keyof UserManagementData>(
  order: TableOrder,
  orderBy: Key,
): (a: { [key in Key]?: string }, b: { [key in Key]?: string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export const UserManagementTable = ({ data }: { data: BusinessUser[] }) => {
  const [order, setOrder] = useState<TableOrder>('asc');
  const [orderBy, setOrderBy] = useState<keyof UserManagementData>('lastName');

  const handleRequestSort = (property: keyof UserManagementData) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const dataRows = useMemo(() => [...data].sort(getComparator(order, orderBy)), [data, order, orderBy]);

  if (!data.length) return null;

  return (
    <Table
      sx={{
        background: 'rgba(14, 7, 29, 0.04)',
        borderRadius: '12px',
        boxShadow: 'none',
      }}
    >
      <UserManagementTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
      <TableBody>
        {dataRows &&
          dataRows.map((user) => (
            <UserManagementTableBody
              key={user.userId}
              name={user.name ?? ''}
              email={user.email}
              phoneNumber={user.phoneNumber}
            />
          ))}
      </TableBody>
    </Table>
  );
};
