import uniq from 'lodash/uniq';
import sortBy from 'lodash/sortBy';
import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { ConfigurationContext } from 'src/app/contexts/configuration/ConfigurationProvider';
import { Table } from 'src/floorplan/domain/Table';
import { Bill, isPaid, isRegistered } from '../domain/Bill';
import { initEffects } from '../effects';
import { BillToDisplay } from './BillToDisplay';
import { getTicketLink } from './getTicketLink';
import { Instant } from 'src/sundayplus/Instant';
import { queryKeys } from 'src/app/queries/utils';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { BusinessId } from 'src/business/domain/Business';

function ignoreBillsWithoutDevice(bill: Bill, tables: Table[] | undefined) {
  const tableForThisBill = tables?.find(({ id }) => bill.order.tableId === id);
  if (tableForThisBill) {
    return tableForThisBill.shortLink !== ''; // No Device associated with the table, we will not show it
  }
  return true;
}

function getPaymentLink(venueId: string | undefined, bill: Bill) {
  const billIssuedAt = Instant.fromEpoch(bill.issuedAt!.unixInMs());
  if (
    bill.sundayBillSplits.filter((billSplit) => isPaid(billSplit) || isRegistered(billSplit)).length > 0 ||
    bill.openTab?.id
  ) {
    const tabIdParam = bill.openTab?.id ? `&tabId=${bill.openTab?.id}` : '';
    const startDate = bill.openTab?.id ? billIssuedAt.subtract(10, 'minutes') : billIssuedAt;
    const endDate = bill.updateAt ? bill.updateAt : billIssuedAt.add(10, 'h');
    return `/venues/${venueId}/payments?preset=CUSTOM&billId=${
      bill.billId
    }${tabIdParam}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;
  }
}

function billsToDisplay(
  b2cAppBaseUrl: string,
  bills: Bill[] | undefined,
  tables: Table[] | undefined,
  businessId: BusinessId,
  patEnrollmentId: EnrollmentId,
): BillToDisplay[] | undefined {
  return bills
    ?.filter((bill: Bill) => ignoreBillsWithoutDevice(bill, tables))
    .map(
      (bill) =>
        ({
          ...bill,
          tableNumber: tables?.find(({ id }) => bill.order.tableId === id)?.number || '-',
          ticketLink: getTicketLink(b2cAppBaseUrl, patEnrollmentId, bill.order.orderId),
          paymentLink: getPaymentLink(businessId, bill),
        }) as BillToDisplay,
    );
}

function waitersFromBills(bills: Bill[] | undefined): string[] {
  return sortBy(uniq(bills?.map((bill) => bill.order.staffName || '')
    ?.filter((staffName) => !!staffName)));
}

export const useBills = (from: Date, to: Date) => {
  const configuration = useContext(ConfigurationContext);
  const {
    till,
    tableDevice,
    billRefresher,
  } = initEffects(configuration);
  const business = useCurrentBusinessOrThrow();
  const fetchFloorPlan = () => tableDevice.listTables(business.id);
  const fetchBills = () => till.searchByDate(business.id, from, to);

  const tables = useQuery({
    queryKey: [queryKeys.FLOOR_PLAN],
    queryFn: fetchFloorPlan,
  });
  const bills = useQuery({
    queryKey: [queryKeys.BILLS, {
      from,
      to,
    }],
    queryFn: fetchBills,
    refetchInterval: 2 * 60 * 1000,
  });

  return {
    isLoading: tables.isLoading || bills.isLoading,
    isError: tables.isError || bills.isError,
    error: tables.error || bills.error,
    refetch: () => bills.refetch(),
    data: {
      bills: billsToDisplay(
        configuration.b2cAppBaseUrl,
        bills.data,
        tables.data,
        business.id,
        business.patEnrollment!.id,
      ),
      waiters: waitersFromBills(bills.data),
      refreshBill: (billId: string, isTab: boolean) => {
        return billRefresher
          .refreshBill(billId, isTab)
          .catch(() => {
          })
          .finally(() => {
            setTimeout(() => bills.refetch(), 5000);
          });
      },
    },
  };
};
