import {
  Bill,
  IssuedSundayBillSplit,
  Order,
  PaidSundayBillSplit,
  PendingSundayBillSplit,
  RegisteredSundayBillSplit,
  SundayBillSplit,
} from '../domain/Bill';
import * as DTO from './BillResponse';
import { BillSplitStatus } from './BillResponse';
import { Instant } from 'src/sundayplus/Instant';
import { BusinessIdSchema } from 'src/business/domain/Business';
import { OpenTab, OpenTabIdSchema } from 'src/bills/domain/tab/open_tab/OpenTab';

const mapOrder = (order: DTO.Order): Order => ({
  orderId: order.orderId.uuid,
  businessId: BusinessIdSchema.parse(order.businessId?.value || order.venueId?.value),
  tableId: order.tableId?.uuid,
  staffName: order.staffName,
  checkNumber: order.orderCode?.value,
});

const mapOpenTab = (tab: DTO.Tab): OpenTab => ({
  id: OpenTabIdSchema.parse(tab.id),
  displayId: tab.displayId,
  userName: tab.customerFullName,
  chargeLeftToPayAmountRequestedAt:
    tab.chargeLeftToPayAmountRequestedAt !== undefined ? Instant.fromString(tab.chargeLeftToPayAmountRequestedAt) : undefined,
  // eslint-disable-next-line max-len
  nominalDigitalFeeRateForCapture: tab.nominalDigitalFeeRateForCapture ?? 300, // TODO: default value is only there for backward/forward compat in case of a rollback of billing, this value is non nullable really
});
const mapSundayBillSplit = (billSplit: DTO.SundayBillSplit): SundayBillSplit => {
  switch (billSplit.status) {
    case BillSplitStatus.ISSUED:
      return {
        discriminant: 'Issued',
      } as IssuedSundayBillSplit;
    case BillSplitStatus.PENDING:
      return {
        discriminant: 'Pending',
      } as PendingSundayBillSplit;
    case BillSplitStatus.PAID:
      return {
        discriminant: 'Paid',
      } as PaidSundayBillSplit;
    case BillSplitStatus.REGISTERED:
      return {
        discriminant: 'Registered',
      } as RegisteredSundayBillSplit;
  }
};

const mapBill = (billResponse: DTO.BillResponse): Bill => ({
  billId: billResponse.billId.id,
  totalBillAmount: billResponse.totalBillAmount,
  issuedAt: billResponse.issuedAt ? Instant.fromString(billResponse.issuedAt) : undefined,
  order: mapOrder(billResponse.order),
  openTab: billResponse.tab ? mapOpenTab(billResponse.tab) : undefined,
  leftToPay: billResponse.leftToPay,
  sundayBillSplits: billResponse.sundayBillSplits.map(mapSundayBillSplit),
  updateAt: billResponse.updatedAt ? Instant.fromString(billResponse.updatedAt) : undefined,
});

export const mapBills = (billResponses: DTO.BillResponse[]): Bill[] => billResponses.map(mapBill);
