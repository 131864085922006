import React from 'react';
import { Box, CircularProgress, Modal, Stack } from '@mui/material';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { withRestrictedAccess } from 'src/app/component/permission/withRestrictedAccess';
import { userRoles } from 'src/domain/user/UserRole';
import { BusinessDetails } from 'src/payments/components/Checkout/CheckoutOnboarding/BusinessDetails/BusinessDetails';
import {
  KeyBusinessStakeholders,
} from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/KeyBusinessStakeholders';
import { useSubmitCheckoutOnboarding } from 'src/payments/mutations/useSubmitCheckoutOnboarding';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { IntlShape, useIntl } from 'src/app/i18n/TypedIntl';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { BankAccount } from 'src/payments/components/Checkout/CheckoutOnboarding/BankAccount/BankAccount';
import { Header } from 'src/payments/components/Checkout/Header';
import { SupportingDocuments } from 'src/payments/page/SupportingDocuments';
import { useGetCheckoutOnboardingInformation } from 'src/payments/infrastructure/useGetCheckoutOnboardingInformation';
import { useNavigate } from 'react-router';
import { useAuthenticatedUserOrThrow } from 'src/auth/hooks/useAuthenticatedUserOrThrow';
import { prefillPhone } from 'src/payments/components/Checkout/CheckoutOnboarding/BusinessDetails/BusinessPhoneNumber';
import {
  ProcessingDetails,
} from 'src/payments/components/Checkout/CheckoutOnboarding/ProcessingDetails/ProcessingDetails';
import { FormActions } from 'src/payments/components/Checkout/CheckoutOnboarding/FormActions';
import { FormStepper } from 'src/payments/components/Checkout/CheckoutOnboarding/Stepper';
import { RouteDefinitions } from 'src/app/RouteDefinitions';
import { venuePath } from 'src/app/navigation/pathHelpers';
import {
  AUTHORISED_SIGNATORY_GLOBAL_ERROR_INDEX_PATH,
} from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/IndividualRepresentatives/AuthorisedSignatory/AuthorisedSignatory';
import {
  LEGAL_REPRESENTATIVES_GLOBAL_ERROR_INDEX_PATH,
} from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/IndividualRepresentatives/LegalRepresentatives/LegalRepresentatives';
import {
  UBO_REPRESENTATIVES_GLOBAL_ERROR_INDEX_PATH,
} from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/IndividualRepresentatives/UBO/UBOs';
import {
  CONTROLLING_COMPANIES_GLOBAL_ERROR_INDEX_PATH,
} from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/ControllingCompanies/ControllingCompanies';
import { Business } from 'src/business/domain/Business';
import {
  CONTROL_REPRESENTATIVES_GLOBAL_ERROR_INDEX_PATH,
} from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/IndividualRepresentatives/Control/Controls';
import {
  DIRECTOR_REPRESENTATIVES_GLOBAL_ERROR_INDEX_PATH,
} from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/IndividualRepresentatives/Director/Directors';


const checkControllingCompanies = (form: UseFormReturn<CheckoutOnboardingInformationDraft>, intl: IntlShape) => {
  const controllingCompanies = form.getValues().controllingCompanies;
  const hasNoControllingCompanies = form.getValues().hasNoControllingCompanies;
  if (!hasNoControllingCompanies && controllingCompanies?.length === 0) {
    form.setError(`controllingCompanies.${CONTROLLING_COMPANIES_GLOBAL_ERROR_INDEX_PATH}`, {
      type: 'manual',
      message: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.controllingCompanies.error' }),
    });
  } else {
    form.clearErrors(`controllingCompanies.${CONTROLLING_COMPANIES_GLOBAL_ERROR_INDEX_PATH}`);
  }

};

const checkAuthorisedSignatory = (form: UseFormReturn<CheckoutOnboardingInformationDraft>, intl: IntlShape) => {
  const representatives = form.getValues().representatives;
  const containsOneAuthorizedSignatory = representatives?.filter((representative) => !!representative.roles?.authorisedSignatory).length === 1;
  if (!containsOneAuthorizedSignatory) {
    form.setError(`representatives.${AUTHORISED_SIGNATORY_GLOBAL_ERROR_INDEX_PATH}`, {
      type: 'manual',
      message: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.representatives.authorisedSignatory.errorMissing' }),
    });
  } else {
    form.clearErrors(`representatives.${AUTHORISED_SIGNATORY_GLOBAL_ERROR_INDEX_PATH}`);
  }
};

const checkLegalRepresentatives = (form: UseFormReturn<CheckoutOnboardingInformationDraft>) => {
  const representatives = form.getValues().representatives;
  const containsAtLeastOneLegalRepresentative = representatives?.some((representative) => !!representative.roles?.legalRepresentative);
  if (!containsAtLeastOneLegalRepresentative) {
    form.setError(`representatives.${LEGAL_REPRESENTATIVES_GLOBAL_ERROR_INDEX_PATH}`, {
      type: 'manual',
      message: 'Au moins un representant légal est requis',
    });
  } else {
    form.clearErrors(`representatives.${LEGAL_REPRESENTATIVES_GLOBAL_ERROR_INDEX_PATH}`);
  }
};

const checkUbos = (form: UseFormReturn<CheckoutOnboardingInformationDraft>, intl: IntlShape) => {
  const representatives = form.getValues().representatives;
  const ubos = representatives?.filter((representative) => !!representative.roles?.ubo) ?? [];
  const hasNoUbos = form.getValues().hasNoUbos;
  // Sylvain: remove constraint about max 4 UBOs. Edge case where there can be more (inheritance, "indivision")
  if (!hasNoUbos && ubos?.length === 0) {
    form.setError(`representatives.${UBO_REPRESENTATIVES_GLOBAL_ERROR_INDEX_PATH}`, {
      type: 'manual',
      message: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.representatives.ubos.errorNoUbos' }),
    });
  } else {
    form.clearErrors(`representatives.${UBO_REPRESENTATIVES_GLOBAL_ERROR_INDEX_PATH}`);
  }
};

const checkControls = (form: UseFormReturn<CheckoutOnboardingInformationDraft>) => {
  const representatives = form.getValues().representatives;
  const atLeastOne = representatives?.some((representative) => !!representative.roles?.control);
  if (!atLeastOne) {
    form.setError(`representatives.${CONTROL_REPRESENTATIVES_GLOBAL_ERROR_INDEX_PATH}`, {
      type: 'manual',
      message: 'At least one control person is required',
    });
  } else {
    form.clearErrors(`representatives.${CONTROL_REPRESENTATIVES_GLOBAL_ERROR_INDEX_PATH}`);
  }
};

const checkDirectors = (form: UseFormReturn<CheckoutOnboardingInformationDraft>) => {
  const representatives = form.getValues().representatives;
  const atLeastOne = representatives?.some((representative) => !!representative.roles?.director);
  if (!atLeastOne) {
    form.setError(`representatives.${DIRECTOR_REPRESENTATIVES_GLOBAL_ERROR_INDEX_PATH}`, {
      type: 'manual',
      message: 'At least one director is required',
    });
  } else {
    form.clearErrors(`representatives.${DIRECTOR_REPRESENTATIVES_GLOBAL_ERROR_INDEX_PATH}`);
  }
};

const customErrorChecks = (form: UseFormReturn<CheckoutOnboardingInformationDraft>, intl: IntlShape, business: Business) => {
  checkAuthorisedSignatory(form, intl);
  checkUbos(form, intl);

  switch (business.address.countryCode) {
    case 'GB':
      checkControllingCompanies(form, intl);
      checkControls(form);
      checkDirectors(form);
      break;
    case 'FR':
      checkControllingCompanies(form, intl);
      checkLegalRepresentatives(form);
      break;
    case 'US':
      checkControls(form);
      break;
    default:
      break;
  }
};

const FixedWidthSideBlock = ({ children }: { children?: React.ReactNode }) => <Box width={'400px'}>{children}</Box>;

const CheckoutOnboarding = ({ defaultValues }: { defaultValues: CheckoutOnboardingInformationDraft }) => {
  const business = useCurrentBusinessOrThrow();
  const navigate = useNavigate();
  const intl = useIntl();
  const snackbar = useSnackbar();

  const form = useForm<CheckoutOnboardingInformationDraft>({
    mode: 'all', criteriaMode: 'all', defaultValues,
  });
  const { mutateAsync: submitCheckoutOnboarding } = useSubmitCheckoutOnboarding({ businessId: business.id });
  const onSave = async () => {
    try {
      await submitCheckoutOnboarding({ checkoutOnboardingInfo: form.getValues(), isDraft: true });
      snackbar.addNotification({
        text: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.save.success' }),
        variant: 'success',
      });
    } catch (e: string | unknown) {
      snackbar.addNotification({
        text: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.save.error' }),
        variant: 'error',
      });
    }
  };


  const onSubmit = async (formData: CheckoutOnboardingInformationDraft) => {
    try {
      const response = await submitCheckoutOnboarding({ checkoutOnboardingInfo: formData, isDraft: false });
      if (response.requirementsDue && response.requirementsDue?.length > 0) {
        snackbar.addNotification({
          text: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.submit.error.requirements_due' }),
          variant: 'error',
        });
      } else {
        navigate(`${venuePath(business.id)}${RouteDefinitions.PaymentsCheckoutOnboardingFormSent.path}`, { replace: true });
      }
    } catch (e: string | unknown) {
      snackbar.addNotification({
        text: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.submit.error' }),
        variant: 'error',
      });
      form.setError('root', {
        type: 'manual',
        message: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.submit.error' }),
      });
    }
  };
  const businessCountryCode = business.address.countryCode;
  return (
    <Modal
      open={true}
      aria-labelledby="finalize-checkout-onboarding"
      style={{ overflow: 'scroll', height: '100%' }}
    >
      <Stack height={'100%'} style={{ background: 'white', height: '100%', overflow: 'auto' }} noValidate
             component={'form'}
             onSubmit={form.handleSubmit(onSubmit, () => {
               console.error('errors in form:', form.formState.errors);
               snackbar.addNotification({
                 text: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.formError' }),
                 variant: 'error',
               });
             })}>
        <FormProvider {...form}>
          <Header
            businessName={business.name}
            businessId={business.id}
            title={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.title' })} onSave={onSave}
            onSubmit={() => customErrorChecks(form, intl, business)}
            onClose={() => navigate(`${venuePath(business.id)}${RouteDefinitions.Home.path}`)} />
          <Stack bgcolor={'white'}
                 padding={'24px 24px 24px 24px'}
                 direction={'row'} justifyContent={'space-between'}>
            <FixedWidthSideBlock>
              <FormStepper />
            </FixedWidthSideBlock>
            <Stack
              spacing={3}
              direction={'column'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Stack style={{ width: '100%', maxWidth: '550px', marginLeft: 'auto', marginRight: 'auto' }} spacing={7}>
                <BusinessDetails businessCountryCode={businessCountryCode} />
                <ProcessingDetails />
                <KeyBusinessStakeholders businessCountryCode={businessCountryCode} />
                <BankAccount countryCode={businessCountryCode} />
                <SupportingDocuments />
                <FormActions
                  status={form.getValues().status}
                  isSubmitting={form.formState.isSubmitting}
                  formIsSubmitted={form.formState.isSubmitSuccessful} onSave={onSave} onSubmit={() => {
                    customErrorChecks(form, intl, business);
                    if (form.formState.isDirty) {
                      snackbar.addNotification({
                        text: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.formDirty' }),
                        variant: 'warning',
                      });
                    }
                  }} />
              </Stack>
            </Stack>
            <FixedWidthSideBlock />
          </Stack>
        </FormProvider>
      </Stack>
    </Modal>
  );
};


const CheckoutOnboardingLoader = () => {
  const business = useCurrentBusinessOrThrow();
  const user = useAuthenticatedUserOrThrow();
  const { data, isLoading } = useGetCheckoutOnboardingInformation({ businessId: business.id });
  // TODO: handle error and when no data
  if (isLoading) return <CircularProgress />;

  return <CheckoutOnboarding defaultValues={{
    ...data!,
    contact: {
      email: !!data?.contact?.email ? data?.contact?.email : user.email ?? '',
      phone: prefillPhone({ phoneFromCheckoutForm: data?.contact?.phone, phoneFromMerchantInfo: user.phoneNumber }),
    },
  }} />;
};

export default withRestrictedAccess([userRoles.ADMIN, userRoles.GENERAL_MANAGER, userRoles.MANAGER], CheckoutOnboardingLoader);
