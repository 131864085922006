import { ChangeEvent } from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { Box, IconButton, TextField } from '@mui/material';
import { Close } from '@mui/icons-material';

interface SearchBarProps {
  onSearch: (e: ChangeEvent<HTMLInputElement>) => void;
  searchQuery: string;
  onClear: () => void;
}

export const UserManagementSearchBar = ({ onSearch, searchQuery, onClear }: SearchBarProps) => {
  const intl = useIntl();

  return (
    <Box>
      <TextField
        fullWidth
        variant="outlined"
        label={intl.formatMessage({ id: 'user_management.search.placeholder', defaultMessage: 'Search' })}
        value={searchQuery}
        onChange={onSearch}
        sx={{
          border: '#DDDDE4',
          borderRadius: '8px',
          '.MuiFormLabel-root': {
            color: '#70707B',
          },
          ...(searchQuery !== '' && {
            '.MuiFormLabel-root': { color: '#FF17E9' },
            '.MuiOutlinedInput-notchedOutline': { borderColor: '#FF17E9' },

            '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#FF17E9' },
          }),
        }}
        slotProps={{
          input: {
            endAdornment: (
              <IconButton sx={{ ...(searchQuery === '' && { display: 'none' }), cursor: 'pointer' }} onClick={onClear}>
                <Close sx={{ color: '#70707B' }} />
              </IconButton>
            ),
          },
        }}
      />
    </Box>
  );
};
