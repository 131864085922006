import { Typography, useTheme } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import React from 'react';

export const NoExtraControlPersonHint = () => {
  const theme = useTheme();
  return <Typography variant={'caption'} color={theme.palette.text.secondary}>
    <FormattedMessage
      id={'settings.payments.checkout.onboarding.representatives.no_extra_control'} />
  </Typography>;
};
