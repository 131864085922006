import { Button } from '@sundayapp/b2b-react-component-library';
import React, { useEffect, useRef } from 'react';
import { Box, CircularProgress, Modal, Typography } from '@mui/material';
import { Money, MoneyView } from '@sundayapp/web-money';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { datadogLogs } from '@datadog/browser-logs';
import { BusinessId } from 'src/business/domain/Business';
import { useVirtualTabCapture } from 'src/bills/infrastructure/tab/useVirtualTabCapture';

type VirtualTabCaptureConfirmationProps = {
  show: boolean;
  onCaptureSuccess: () => void;
  onCancel: () => void;
  billLeftToPay: Money;
  tabId: string;
  businessId: BusinessId;
  digitalFeeRate: number;
};

const style = {
  position: 'fixed',
  top: '50vh',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '24px',
  boxShadow: 24,
  p: 4,
};

export const VirtualTabCaptureConfirmation = ({
  show,
  onCancel,
  onCaptureSuccess,
  billLeftToPay,
  businessId,
  tabId,
}: VirtualTabCaptureConfirmationProps) => {
  const timeoutRef = useRef<number | undefined>(undefined);
  const { capture, status } = useVirtualTabCapture(businessId, tabId);
  const isCapturing = status === 'capturing';

  const snackBar = useSnackbar();
  const intl = useIntl();

  useEffect(() => {
    return () => {
      if (!!timeoutRef.current) {
        window.clearTimeout(timeoutRef.current);
      }
    };
  }, []);
  const onFailure = () => {
    snackBar.addNotification({
      variant: 'error',
      text: intl.formatMessage({ id: 'tabs.charge.left_to_pay.failure' }),
    });
    onCancel();
  };
  const onSuccess = () => {
    onCaptureSuccess();
    snackBar.addNotification({
      variant: 'success',
      text: intl.formatMessage({ id: 'tabs.charge.left_to_pay.success' }),
    });
    onCancel();
  };
  const onClick = async () => {
    await capture(billLeftToPay);
    timeoutRef.current = window.setTimeout(() => {
      datadogLogs.logger.error('[CAPTURE TAB LEFT TO PAY] timeout on completing capture', { tabId, businessId });
      onFailure();
    }, 8000);
  };

  if (status === 'error') {
    datadogLogs.logger.error('[CAPTURE TAB LEFT TO PAY] capture failed', { tabId, businessId });
    onFailure();
  }

  if (status === 'success') {
    onSuccess();
  }


  const title = isCapturing ? 'Capture in progress...' : 'Confirm charge and close the tab';
  const description = isCapturing ? (
    'please wait until completion.'
  ) : (
    <>
      {'charged amount will be : '}
      <MoneyView value={billLeftToPay} />
    </>
  );
  return (
    <Modal open={show} onClose={onCancel}>
      <Box sx={style}>
        <Typography variant={'h5'}>
          <p>{title}</p>
        </Typography>
        <Typography variant={'button'}>
          <p>{description}</p>
        </Typography>
        {isCapturing && (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress size={'large'} />
          </div>
        )}
        {!isCapturing && (
          <>
            <div style={{ paddingBottom: '16px' }}>
              <Button variant="warning" size="medium" fullWidth onClick={onClick} data-testid="confirm-capture">
                {'charge and close'}
              </Button>
            </div>
            <div>
              <Button
                variant="secondary"
                size="medium"
                fullWidth
                onClick={() => {
                  if (!isCapturing) {
                    onCancel();
                  }
                }}
                data-testid="cancel-capture"
              >
                {'cancel'}
              </Button>
            </div>
          </>
        )}
      </Box>
    </Modal>
  );
};
