import axios from 'axios';
import { BusinessId } from 'src/business/domain/Business';
import { ValuesOf } from 'src/utils/typescript/valuesOf';

export const chargebeePaymentMethodStatus = {
  VALID: 'VALID',
  EXPIRING: 'EXPIRING',
  EXPIRED: 'EXPIRED',
  INVALID: 'INVALID',
  PENDING_VERIFICATION: 'PENDING_VERIFICATION',
  NO_PAYMENT_METHOD: 'NO_PAYMENT_METHOD',
  UNKNOWN: 'UNKNOWN',
  NO_ACCOUNT: 'NO_ACCOUNT',
} as const;
export type ChargebeePaymentMethodStatusType = ValuesOf<typeof chargebeePaymentMethodStatus>;


type PaymentMethodResponseType = {
  status: ChargebeePaymentMethodStatusType
};

type CreatePortalResponseType = {
  accessUrl: string
};

export class BookkeepingRepositoryHttp {
  constructor(
    private bookkeepingBaseUrl: string,
  ) {
  }

  async getChargebeePaymentMethodStatus(businessId: BusinessId): Promise<ChargebeePaymentMethodStatusType> {
    return axios.get<PaymentMethodResponseType>(`${this.bookkeepingBaseUrl}/chargebee/${businessId}/payment-method-status`)
      .then(response => response.data.status)
      .catch(() => chargebeePaymentMethodStatus.NO_ACCOUNT);
  }

  async createPortalSession(businessId: BusinessId, redirectUrl: string): Promise<string> {
    return (await axios.post<CreatePortalResponseType>(
      `${this.bookkeepingBaseUrl}/chargebee/${businessId}/create-portal-session`,
      null,
      { params: { redirect_url: redirectUrl } }))
      .data.accessUrl;
  }

}
