import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import * as React from 'react';
import { Card, CardContent, CardHeader, Stack, styled, Switch, Typography } from '@mui/material';
import { TipsConfiguration, TipsLevelConfiguration } from '../../domain/PaymentTerminalConfiguration';
import TipsLevelForm from './TipsLevelForm';
import { breakpoints } from 'src/app/theme/ThemeV5';
import { useAuthenticatedUserOrThrow } from 'src/auth/hooks/useAuthenticatedUserOrThrow';

export type TipsSettingsProps = {
  tipsConfig: TipsConfiguration;
  onTipsUpdated: (configuration: TipsConfiguration) => void;
};

const TipsLevelStack = styled('div')`
  flex-direction: column;
  justify-content: start;
  align-items: start;
  display: flex;
  gap: 10px;
  @media (max-width: ${breakpoints.tablet}) {
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }
`;

function TipsForm({ onTipsUpdated, tipsConfig }: TipsSettingsProps) {
  const intl = useIntl();
  const user = useAuthenticatedUserOrThrow();

  function onUserExperienceUpdate(checked: boolean) {
    if (checked) {
      onTipsUpdated({ ...tipsConfig, userExperienceEnabled: checked });
    } else {
      onTipsUpdated({
        ...tipsConfig,
        userExperienceEnabled: checked,
        tipsLevels: { ...tipsConfig.tipsLevels, level3: { ...tipsConfig.tipsLevels.level3, enabled: true } },
      });
    }
  }

  return (
    <Card sx={{ padding: '16px' }}>
      <CardHeader
        title={intl.formatMessage({ id: 'payment.terminal.settings.tips.title' })}
        action={user.impersonated &&
          <Switch
            checked={tipsConfig.tipsEnabled}
            onChange={(e: any) => onTipsUpdated({ ...tipsConfig, tipsEnabled: e.target.checked })}
          />
        }
      />
      <CardContent>
        <Stack direction="column" justifyContent="space-evenly" spacing="20px">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography>
              <FormattedMessage id="payment.terminal.settings.user.rounded.tips.enabled" />
            </Typography>
            <Switch
              checked={tipsConfig.roundedTipsEnabled}
              disabled={!tipsConfig.tipsEnabled}
              onChange={(e: any) => onTipsUpdated({ ...tipsConfig, roundedTipsEnabled: e.target.checked })}
            />
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography>
              <FormattedMessage id="payment.terminal.settings.user.experience.enabled" />
            </Typography>
            <Switch
              checked={tipsConfig.userExperienceEnabled}
              disabled={!tipsConfig.tipsEnabled}
              onChange={(e: any) => onUserExperienceUpdate(e.target.checked)}
            />
          </Stack>
          {tipsConfig.userExperienceEnabled ?
            <TipsLevelStack>
              <TipsLevelForm
                disabled={!tipsConfig.tipsEnabled || !tipsConfig.userExperienceEnabled}
                name={intl.formatMessage({ id: 'payment.terminal.settings.tips.level1.name' })}
                tipsLevel={tipsConfig.tipsLevels.level1}
                onTipsLevelUpdated={(config: TipsLevelConfiguration) =>
                  onTipsUpdated({
                    ...tipsConfig,
                    tipsLevels: {
                      ...tipsConfig.tipsLevels,
                      level1: { ...config },
                    },
                  })}
              />
              <TipsLevelForm
                disabled={!tipsConfig.tipsEnabled || !tipsConfig.userExperienceEnabled}
                name={intl.formatMessage({ id: 'payment.terminal.settings.tips.level2.name' })}
                tipsLevel={tipsConfig.tipsLevels.level2}
                onTipsLevelUpdated={(config: TipsLevelConfiguration) =>
                  onTipsUpdated({
                    ...tipsConfig,
                    tipsLevels: {
                      ...tipsConfig.tipsLevels,
                      level2: { ...config },
                    },
                  })}
              />
              <TipsLevelForm
                disabled={!tipsConfig.tipsEnabled}
                name={intl.formatMessage({ id: 'payment.terminal.settings.tips.level3.name' })}
                tipsLevel={tipsConfig.tipsLevels.level3}
                onTipsLevelUpdated={(config: TipsLevelConfiguration) =>
                  onTipsUpdated({
                    ...tipsConfig,
                    tipsLevels: {
                      ...tipsConfig.tipsLevels,
                      level3: { ...config },
                    },
                  })}
              />
            </TipsLevelStack>
            :
            <TipsLevelStack>
              <TipsLevelForm
                disabled={!tipsConfig.tipsEnabled}
                name={undefined}
                tipsLevel={tipsConfig.tipsLevels.level3}
                onTipsLevelUpdated={(config: TipsLevelConfiguration) =>
                  onTipsUpdated({
                    ...tipsConfig,
                    tipsLevels: {
                      ...tipsConfig.tipsLevels,
                      level3: { ...config },
                    },
                  })}
              />
            </TipsLevelStack>
          }
        </Stack>
      </CardContent>
    </Card>
  );
}

export default TipsForm;
