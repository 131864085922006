import { Instant } from 'src/sundayplus/Instant';

import { z } from 'zod';

export const OpenTabIdSchema = z.string().brand<'open_tab_id'>();
export type OpenTabId = z.infer<typeof OpenTabIdSchema>;

export interface OpenTab {
  id: OpenTabId;
  displayId: string;
  userName: string;
  chargeLeftToPayAmountRequestedAt?: Instant;
  nominalDigitalFeeRateForCapture: number;
}
