import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import axios from 'axios';
import { BusinessId } from 'src/business/domain/Business';
import { useMutation } from '@tanstack/react-query';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { CheckoutOnboardingInformationDto } from 'src/payments/infrastructure/CheckoutOnboardingInformationDto';
import {
  mapCheckoutInformationFromDomainToDto,
} from 'src/payments/infrastructure/mapper/mapCheckoutInformationFromDomainToDto';


const sendCheckoutOnboarding = async (businessId: BusinessId, checkoutOnboardingInfo: CheckoutOnboardingInformationDraft, isDraft: boolean): Promise<CheckoutOnboardingInformationDto> => {
  try {
    let axiosResponse = await axios.put<CheckoutOnboardingInformationDto>(
      `${ConfigurationLoader.load().paymentApiBaseUrl}/checkout/onboarding/${businessId.toString()}`,
      mapCheckoutInformationFromDomainToDto(isDraft, checkoutOnboardingInfo),
    );
    return axiosResponse.data;
  } catch (error) {
    throw new Error('Failed to submit checkout onboarding');
  }
};

export const useSubmitCheckoutOnboarding = ({ businessId }: { businessId: BusinessId }) => {
  return useMutation({
    mutationFn: async ({ checkoutOnboardingInfo, isDraft }: {
      checkoutOnboardingInfo: CheckoutOnboardingInformationDraft,
      isDraft: boolean,
    }): Promise<CheckoutOnboardingInformationDto> => {
      return sendCheckoutOnboarding(businessId, checkoutOnboardingInfo, isDraft);
    },
  });
};

