import { useState } from 'react';
import { ConfigurationReplicationState } from 'src/enterprise/configuration-replication/domain/ConfigurationReplicationState';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { Card, CardHeader, Stack } from '@mui/material';
import { SelectSourceBusinessStep } from 'src/enterprise/configuration-replication/components/SelectSourceBusinessStep';
import { SelectConfigurationsStep } from 'src/enterprise/configuration-replication/components/SelectConfigurationsStep';
import { SelectTargetBusinessesStep } from 'src/enterprise/configuration-replication/components/SelectTargetBusinessesStep';
import { ReviewAndApplyChangesStep } from 'src/enterprise/configuration-replication/components/ReviewAndApplyChangesStep';

export const ConfigurationReplicationPage = () => {
  const [state, setState] = useState<ConfigurationReplicationState>({
    step: 'select source business',
  });

  const setNextState = (nextState: ConfigurationReplicationState) => setState(nextState);

  const intl = useIntl();

  return (
    <Stack gap="1rem">
      <Card>
        <CardHeader title={intl.formatMessage({ id: 'configuration_replication.title' })} />
        {state.step === 'select source business' && <SelectSourceBusinessStep onNext={setNextState} />}
        {state.step === 'select configurations' && (
          <SelectConfigurationsStep currentState={state} onNext={setNextState} />
        )}
        {state.step === 'select target businesses' && (
          <SelectTargetBusinessesStep currentState={state} onNext={setNextState} />
        )}
        {state.step === 'review and apply changes' && <ReviewAndApplyChangesStep currentState={state} />}
      </Card>
    </Stack>
  );
};
