import { IconButton, TableCell, TableRow } from '@mui/material';
import { MoveVerticalIcon } from 'src/enterprise/configuration-replication/components/icons/MoveVerticalIcon';
import { useSearchParams } from 'react-router';
import _ from 'lodash';

type UserData = {
  name: string;
  email: string;
  phoneNumber: string | undefined;
  locations?: string[];
};

const HighlightedText = ({ text = '', highlight = '' }) => {
  if (!highlight.trim()) {
    return <span>{text}</span>;
  }
  const regex = new RegExp(`(${_.escapeRegExp(highlight)})`, 'gi');
  const parts = text.split(regex);

  return (
    <span>
      {parts
        .filter((part) => part)
        .map((part, i) =>
          regex.test(part) ? (
            <span key={i} style={{ color: '#FF17E9', fontWeight: 500 }}>
              {part}
            </span>
          ) : (
            <span key={i}>{part}</span>
          ),
        )}
    </span>
  );
};

export const UserManagementTableBody = ({ name, email, phoneNumber }: UserData) => {
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get('search_query') ?? '';

  return (
    <TableRow>
      <TableCell>
        <HighlightedText text={name} highlight={searchQuery} />
      </TableCell>
      <TableCell>
        <HighlightedText text={email} highlight={searchQuery} />
      </TableCell>
      <TableCell>
        <HighlightedText text={phoneNumber} highlight={searchQuery} />
      </TableCell>
      <TableCell></TableCell>
      <TableCell>
        <IconButton>
          <MoveVerticalIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};
