import { useContext, useState } from 'react';
import { ConfigurationContext } from 'src/app/contexts/configuration/ConfigurationProvider';
import { initEffects } from '../../effects';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from 'src/app/queries/utils';
import { BusinessId } from 'src/business/domain/Business';

export const useOpenTabCapture = (businessId: BusinessId, tabId: string) => {
  const [isCapturing, setIsCapturing] = useState(false);
  const configuration = useContext(ConfigurationContext);
  const { till } = initEffects(configuration);

  const getStatus = () => till.openTabCaptureStatus(businessId, tabId);

  const status = useQuery({
    queryKey: [queryKeys.tabs.CAPTURE_STATUS, { businessId, tabId }],
    queryFn: getStatus,
    refetchInterval: 1000,
    enabled: isCapturing,
  });

  return {
    isLoading: status.isLoading,
    isError: status.isError,
    isCapturing,
    capture: async () => {
      setIsCapturing(true);
      return till.captureOpenTabLeftToPay(businessId, tabId);
    },
    abort: () => {
      setIsCapturing(false);
    },
    data: {
      status: status.data,
    },
  };
};
