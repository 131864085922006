export const ChevronLeftIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        /* eslint-disable-next-line max-len */
        d="M6.91009 15.5892C6.58466 15.2637 6.58466 14.7361 6.91009 14.4107L11.3208 9.99992L6.91009 5.58917C6.58466 5.26374 6.58466 4.7361 6.91009 4.41066C7.23553 4.08523 7.76317 4.08523 8.0886 4.41066L13.0886 9.41066C13.414 9.7361 13.414 10.2637 13.0886 10.5892L8.0886 15.5892C7.76317 15.9146 7.23553 15.9146 6.91009 15.5892Z"
        fill="#70707B"
      />
    </svg>
  );
};
