import { Alert, Stack, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { ControllingCompanies } from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/ControllingCompanies/ControllingCompanies';
import { AuthorisedSignatory } from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/IndividualRepresentatives/AuthorisedSignatory/AuthorisedSignatory';
import { LegalRepresentatives } from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/IndividualRepresentatives/LegalRepresentatives/LegalRepresentatives';
import { UBOs } from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/IndividualRepresentatives/UBO/UBOs';
import { Controls } from './IndividualRepresentatives/Control/Controls';
import { Directors } from './IndividualRepresentatives/Director/Directors';

export const KeyBusinessStakeholders = ({ businessCountryCode }: { businessCountryCode: string }) => {
  return <Stack direction="column" spacing={3}>
    <Typography variant={'h5'}>
      3 · <FormattedMessage id={'settings.payments.checkout.onboarding.keyStakeholders'} />
    </Typography>
    {
      businessCountryCode === 'GB' && <Alert severity="info">
        <Typography>
          <FormattedMessage id={'settings.payments.checkout.onboarding.key_business_stakeholders.helper_text.gb'} />
        </Typography>
      </Alert>
    }
    {
      businessCountryCode === 'US' && <Alert severity="info">
        <Typography>
          <FormattedMessage id={'settings.payments.checkout.onboarding.key_business_stakeholders.helper_text.us'} />
        </Typography>
      </Alert>
    }
    <Stack spacing={3}>
      <AuthorisedSignatory businessCountryCode={businessCountryCode} />
      <UBOs businessCountryCode={businessCountryCode} />
      {businessCountryCode === 'FR' && <LegalRepresentatives />}
      {['GB', 'US'].includes(businessCountryCode) && <Controls businessCountryCode={businessCountryCode} />}
      {businessCountryCode === 'GB' && <Directors />}
      {businessCountryCode !== 'US' && <ControllingCompanies />}
    </Stack>
  </Stack>;
};
