import { useFormContext } from 'react-hook-form';
import { Address } from 'src/payments/components/Checkout/CheckoutOnboarding/BusinessDetails/Address';
import React from 'react';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { Stack, Typography, useTheme } from '@mui/material';

export const PrimaryAddress = () => {
  const { formState: { errors }, register } = useFormContext<CheckoutOnboardingInformationDraft>();
  const primaryAddressErrors = errors.primaryAddress;
  const intl = useIntl();
  const theme = useTheme();
  return <Stack direction="column" spacing={3}>
    <Stack spacing={1}>
      <Typography
        variant={'h6'}>{intl.formatMessage({ id: 'settings.payments.checkout.onboarding.primaryAddress' })}</Typography>
      <Typography variant={'caption'} color={theme.palette.grey.A700}>
        <FormattedMessage id={'settings.payments.checkout.onboarding.primaryAddress.helper_text'} />
      </Typography>
    </Stack>
    <Address
      addressType={'primaryAddress'}
      addressErrors={{
        addressLine1: primaryAddressErrors?.addressLine1,
        zip: primaryAddressErrors?.zip,
        city: primaryAddressErrors?.city,
        state: primaryAddressErrors?.state,
      }} register={register} />
  </Stack>;
};
