import { Box } from '@mui/material';
import { Role } from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/Role';
import {
  NoExtraControlPersonHint,
} from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/IndividualRepresentatives/UBO/NoExtraControlPersonHint';
import React from 'react';

export const ControlPersonExtraRole = ({ readOnly, representativeIndex, label }: {
  label: string,
  readOnly: boolean,
  representativeIndex: number
}) => {

  return <Box display={'flex'} flexDirection={'column'} gap={0}>
    <Role
      isReadOnly={readOnly}
      role={'control'}
      representativeIndex={representativeIndex}
      label={label}
    />
    {readOnly && <NoExtraControlPersonHint />}
  </Box>;
};
