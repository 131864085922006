import { useFormContext } from 'react-hook-form';
import { FormControl, FormGroup, FormHelperText, Stack } from '@mui/material';
import React from 'react';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { Role } from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/Role';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import {
  hasAlreadyControlRepresentatives,
} from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/IndividualRepresentatives/AuthorisedSignatory/hasAlreadyControlRepresentatives';
import {
  ControlPersonExtraRole,
} from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/IndividualRepresentatives/AuthorisedSignatory/ControlPersonExtraRole';


export const UboExtraRoles = ({ representativeIndex }: { representativeIndex: number }) => {
  const business = useCurrentBusinessOrThrow();
  const { formState: { errors }, watch } = useFormContext<CheckoutOnboardingInformationDraft>();
  const error = errors?.representatives?.[representativeIndex]?.roles;
  const intl = useIntl();
  const representatives = watch('representatives');
  const hasAlreadyControl = hasAlreadyControlRepresentatives(representatives, representativeIndex);
  const extraControlPersonLabel = intl.formatMessage({ id: 'settings.payments.checkout.onboarding.representatives.ubo.extraRole.control' });
  return <FormControl component="fieldset">
    <Stack spacing={2}>
      <FormGroup>
        {business.address.countryCode == 'FR' && <Role role={'legalRepresentative'}
                                                       representativeIndex={representativeIndex}
                                                       label={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.representatives.ubo.extraRole.legalRepresentative' })} />}
        {business.address.countryCode == 'GB' &&
          <>
            <ControlPersonExtraRole label={extraControlPersonLabel} representativeIndex={representativeIndex}
                                    readOnly={hasAlreadyControl} />
            <Role role={'director'}
                  representativeIndex={representativeIndex}
                  label={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.representatives.ubo.extraRole.director' })} />
          </>
        }
        {business.address.countryCode == 'US' &&
          <ControlPersonExtraRole label={extraControlPersonLabel} representativeIndex={representativeIndex}
                                  readOnly={hasAlreadyControl} />
        }
      </FormGroup>
    </Stack>
    <FormHelperText error={!!error}>{!!error ? error?.message : ''}</FormHelperText>
  </FormControl>
  ;
};
